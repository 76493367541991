import {mdiArrowRight, mdiCheck, mdiCloseOctagon, mdiSendClock} from '@mdi/js';
import Icon from '@mdi/react';
import axios from 'axios';
import {motion, useTransform} from 'framer-motion';
import {graphql} from 'gatsby';
import React, {useState} from 'react';
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import Layout from '../components/Layout';
import Seo from '../components/seo';
import TechLogos from '../components/TechLogos';
// import { useReduceMotion } from "react-reduce-motion"
import {useScrollAnimation} from '../components/useScrollAnimation';
import BannerMp4 from '../videos/contact.mp4';
import BannerOgv from '../videos/contact.ogv';
import BannerWebm from '../videos/contact.webm';
import BannerCover from '../videos/video_cover_contact.jpg';

function Form() {

  const {executeRecaptcha} = useGoogleReCaptcha();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formStatus, setFormStatus] = useState({
    status: 'ready',
    btnLabel: 'Submit Message',
    btnIcon: mdiArrowRight,
  });


  const handleSubmit = async e => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    data.append('g-recaptcha-response', await executeRecaptcha());
    setIsSubmitting(true);
    setFormStatus({
      status: 'submitting',
      btnLabel: 'Sending',
      btnIcon: mdiSendClock,
    });
    axios({
      method: 'post',
      url: 'https://getform.io/f/bb04424d-094b-49bd-9cf3-f2348252f9eb',
      data,
    })
      .then(r => {

        setFormStatus({
          status: 'submitting',
          btnLabel: 'Sent',
          btnIcon: mdiCheck,
        });

        form.reset();

        setIsSubmitting(false);

      })
      .catch(r => {

        console.log(r.response.data.error);
        setIsSubmitting(false);
        setFormStatus({
          status: 'failed',
          btnLabel: 'Failed to send! Try again?',
          btnIcon: mdiCloseOctagon,
        });
      });
  };

  return (
    <form onSubmit={handleSubmit} method="post">
      <fieldset>
        <label htmlFor="Name" required="required">Name</label>
        <input type="text" name="Name" required="required" placeholder="Name" disabled={isSubmitting}/>
      </fieldset>
      <fieldset>
        <label htmlFor="Email" required="required">Email</label>
        <input type="email" name="Email" required="required" placeholder="Your email address" disabled={isSubmitting}/>
      </fieldset>
      <fieldset>
        <label htmlFor="Message" required="required">Message</label>
        <textarea type="text" name="Message" rows="5" cols="33" placeholder="Write your message" required="required"
                  disabled={isSubmitting}></textarea>
      </fieldset>
      <fieldset>

        <button
          type="submit"
          className="button icon-right contact-submit"
          disabled={isSubmitting}
          style={{marginLeft: 'auto', display: 'block'}}
        >
          {formStatus.btnLabel} <Icon path={formStatus.btnIcon} size={1.3}/>
        </button>
      </fieldset>

    </form>
  );
}

export default function Contact({data}) {


  const {contactInfo, reCaptchaKey} = data.site.siteMetadata;

  // const prefersReducedMotion = useReduceMotion();
  const prefersReducedMotion = false; //react-reduce-motion fails at build time due to window

  const [bannerRef, bannerScroll] = useScrollAnimation();
  const bannerScale = useTransform(bannerScroll, [0, 0.5, 1], prefersReducedMotion ? [1, 1, 1] : [1, 1, 1.2]);


  return (

    <Layout>

      <Seo
        title="Software developers Napier, Hawke’s Bay, NZ | Webfox - Get in touch"
        description="You might have a vision, or you might not know yet what your software solution needs to look like - either way, we’d love to hear from you."
      />

      {/* section1 */}
      <div ref={bannerRef}>
        <section className="layer intro intro-default dark">
          <motion.video animate={{scale: bannerScale.get()}} id="background-video" autoPlay loop muted playsInline poster={BannerCover}>
            <source src={BannerWebm} type="video/webm"/>
            <source src={BannerMp4} type="video/mp4"/>
            <source src={BannerOgv} type="video/ogg"/>
          </motion.video>

          <div className="inner">
            <div className="copy">
              <h1>Get in touch</h1>
            </div>
          </div>
        </section>
      </div>

      {/* section 2 */}
      <section className="layer singlecol transparent">
        <div className="inner">

          <h2>You might have a vision, or you might not know yet what your software solution needs to look like.</h2>
          <p>Either way, we’d love to hear from you. As consultants and strategists, we can help you understand what your business really needs, and
            as developers, we’ll bring that idea to a digital reality.</p>
          <p>While our HQ is based in Napier, Hawke’s Bay, we partner with organizations in New Zealand, Australia, and anywhere in the world.</p>

          <div className="contact-box grid col-3">
            {contactInfo.map((contact) => (
              <div className="col">
                <h3>{contact.name}</h3>
                <p>{contact.address.map((line, index) => (
                  <span key={index}>{line}<br/></span>
                ))}</p>
                <p>p. <a href={`tel:${contact.phoneLink}`}>{contact.phone}</a><br/>
                  e. <a href={`mailto:${contact.emailInfo}`}>{contact.emailInfo}</a>
                </p>
                <div className="button-group">
                  <a href="https://goo.gl/maps/LXv3T1D9EfobhT3SA" target="_blank" rel="noreferrer" className="button icon-right">View
                    Map <Icon path={mdiArrowRight} size={1.3}/></a>
                </div>
              </div>
            ))}
            <div className="col">
              <h3>Postal:</h3>
              <p>Webfox Developments Ltd<br/>
                P.O Box 1164<br/>
                Napier 4140<br/>
                New Zealand
              </p>
            </div>
          </div>

          <h3>Send us a message</h3>

          <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>

            <Form/>

          </GoogleReCaptchaProvider>

          <div className="contact-speaking-event">
            <h3>Would you like James Simmonds, Director of Webfox, to speak at your next event?</h3>
            <p>
              At Webfox, we're leading the way in tech innovation across New Zealand, with a focus on AI and custom software solutions. James has spoken at various events, offering practical insights into how businesses can use AI and custom software to streamline operations, enhance productivity, achieve growth, and become leaders in their industry.<br/><br/>If you'd like to have James speak at your event, please reach out using the form above or email{' '}
              <a href="mailto:alexis@webfox.co.nz">alexis@webfox.co.nz</a>
            </p>
          </div>
        </div>
      </section>

      <TechLogos/>

    </Layout>


  );
}

export const query = graphql`
  query ContactInfo {
    site {
      siteMetadata {
        reCaptchaKey
        contactInfo {
          name
          address
          phone
          phoneLink
          emailInfo
        }
      }
    }
  }

`;
